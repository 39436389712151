import React from "react"

import { Field } from "formik"

import { Col, Form, InputGroup } from "react-bootstrap"

function BedroomField(props) {
    return (
        <>
            {/* Bedroom Field */}
            <Col xs={6} className="ps-md-2">
                <Form.Group className="mb-4">
                    <Form.Label htmlFor={"minBedrooms" + props.searchType}>
                        <span className="text-semi-bold text-sm">Bedrooms</span>
                    </Form.Label>

                    <InputGroup>
                        <Field
                            as="select"
                            id={"minBedrooms" + props.searchType}
                            name="minBedrooms"
                            className="form-control form-select rounded-0"
                            type="button"
                        >
                            <option value="0">Any</option>

                            <option value="2">2+</option>
                            <option value="3">3+</option>
                            <option value="4">4+</option>
                            <option value="5">5+</option>
                        </Field>
                    </InputGroup>
                </Form.Group>
            </Col>
            {/* Bedroom Field End */}
        </>
    )
}

export default BedroomField
