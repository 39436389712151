import React from "react"
import { useRouter } from "next/router"
import Image from "next/image"

import { Formik } from "formik"
import { Form as FormikForm } from "formik"

import { Tab, Nav, Container, Card, Row, Col, Button } from "react-bootstrap"

import PriceField from "@/fields/priceField"
import BedroomField from "@/fields/bedroomField"
import SubmitButton from "@/fields/submitButton"

function PropertySearch(props) {
    const router = useRouter()

    const { trans_type_id, min_price, max_price, min_bedrooms } = router.query

    return (
        <Container
            as={Card}
            fluid="md"
            className={props.title ? "pb-2 card-overlap-2" : "pb-2 mt-6"}
        >
            {props.title && (
                <h2
                    className="h4 mb-3 mb-md-0 ms-3 d-inline"
                    style={{
                        position: "relative",
                        top: "2rem",
                    }}
                >
                    {props.title}
                </h2>
            )}
            <Tab.Container
                defaultActiveKey={
                    trans_type_id ? trans_type_id + "Trans" : "1Trans"
                }
            >
                <Row className="mobile-marg-0 align-items-center">
                    <Col md={3} className="pt-4 pt-md-4">
                        <Nav
                            justify
                            variant="pills"
                            className="ms-md-1 mt-md-2 nav-pills border"
                            defaultActiveKey="1Trans"
                        >
                            <Nav.Item>
                                <Nav.Link
                                    role="button"
                                    href="#"
                                    tabIndex="0"
                                    eventKey="1Trans"
                                    style={{
                                        cursor: "pointer",
                                        height: "2.4rem",
                                    }}
                                    className="text-sm text-bold d-flex align-items-center justify-content-center"
                                >
                                    Buy
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    role="button"
                                    href="#"
                                    tabIndex="0"
                                    eventKey="2Trans"
                                    style={{
                                        cursor: "pointer",
                                        height: "2.4rem",
                                    }}
                                    className="text-sm text-bold d-flex align-items-center justify-content-center"
                                >
                                    Rent
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col>
                        <Tab.Content>
                            <Tab.Pane eventKey="1Trans">
                                <Formik
                                    initialValues={
                                        trans_type_id === "1"
                                            ? {
                                                  transTypeId: 1,

                                                  MinBuy: min_price || "",
                                                  MaxBuy: max_price || "",
                                                  minBedrooms:
                                                      min_bedrooms || "",
                                              }
                                            : {
                                                  transTypeId: 1,

                                                  MinBuy: "",
                                                  MaxBuy: "",
                                                  minBedrooms:
                                                      min_bedrooms || "",
                                              }
                                    }
                                    // Send the form data to the backend
                                    onSubmit={async (values) => {
                                        router.pathname = "properties"
                                        router.query.page = 1
                                        router.query.trans_type_id =
                                            values.transTypeId
                                        router.query.min_price = values.MinBuy
                                        router.query.max_price = values.MaxBuy
                                        router.query.min_bedrooms =
                                            values.minBedrooms

                                        router.push(router)
                                    }}
                                >
                                    {(formik) => (
                                        <FormikForm>
                                            <Row className="align-items-center mt-4">
                                                <Col xs={12} md={6}>
                                                    <Row>
                                                        <PriceField
                                                            placement="Property List Search"
                                                            searchType="Buy"
                                                            level="Min"
                                                            formik={formik}
                                                        />
                                                        <PriceField
                                                            placement="Property List Search"
                                                            searchType="Buy"
                                                            level="Max"
                                                            formik={formik}
                                                        />
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <Row className="align-items-center">
                                                        <BedroomField searchType="Buy" />
                                                        <Col
                                                            xs={6}
                                                            className="mt-1"
                                                        >
                                                            <SubmitButton
                                                                search={true}
                                                                submit_message={
                                                                    <>
                                                                        <span className="d-none d-sm-inline d-md-none d-lg-inline text-white me-2">
                                                                            Search
                                                                        </span>
                                                                        <Image
                                                                            src="https://webbjenkinsstatic.imgix.net/icons/search-icon.svg"
                                                                            alt="Search icon magnifying glass"
                                                                            height={
                                                                                12
                                                                            }
                                                                            width={
                                                                                12
                                                                            }
                                                                            className="my-auto"
                                                                        />
                                                                    </>
                                                                }
                                                                // loading={loading}
                                                                formik={formik}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </FormikForm>
                                        // Property Search Form End
                                    )}
                                </Formik>
                            </Tab.Pane>
                            <Tab.Pane eventKey="2Trans">
                                <Formik
                                    initialValues={
                                        trans_type_id === "2"
                                            ? {
                                                  transTypeId: 2,

                                                  MinRent: min_price || "",
                                                  MaxRent: max_price || "",
                                                  minBedrooms:
                                                      min_bedrooms || "",
                                              }
                                            : {
                                                  transTypeId: 2,

                                                  MinRent: "",
                                                  MaxRent: "",
                                                  minBedrooms:
                                                      min_bedrooms || "",
                                              }
                                    }
                                    // Send the form data to the backend
                                    onSubmit={async (values) => {
                                        router.pathname = "properties"
                                        router.query.page = 1
                                        router.query.trans_type_id =
                                            values.transTypeId
                                        router.query.min_price = values.MinRent
                                        router.query.max_price = values.MaxRent
                                        router.query.min_bedrooms =
                                            values.minBedrooms

                                        router.push(router)
                                    }}
                                >
                                    {(formik) => (
                                        <FormikForm>
                                            <Row className="align-items-center mt-4">
                                                <Col md={6}>
                                                    <Row>
                                                        <PriceField
                                                            searchType="Rent"
                                                            level="Min"
                                                            formik={formik}
                                                        />
                                                        <PriceField
                                                            searchType="Rent"
                                                            level="Max"
                                                            formik={formik}
                                                        />
                                                    </Row>
                                                </Col>
                                                <Col md={6}>
                                                    <Row className="align-items-center">
                                                        <BedroomField />
                                                        <Col
                                                            xs={6}
                                                            className="mt-1"
                                                        >
                                                            <SubmitButton
                                                                search={true}
                                                                submit_message={
                                                                    <>
                                                                        <span className="d-none d-sm-inline d-md-none d-lg-inline text-white me-2">
                                                                            Search
                                                                        </span>
                                                                        <Image
                                                                            src="https://webbjenkinsstatic.imgix.net/icons/search-icon.svg"
                                                                            alt="Search icon magnifying glass"
                                                                            height={
                                                                                12
                                                                            }
                                                                            width={
                                                                                12
                                                                            }
                                                                            className="my-auto"
                                                                        />
                                                                    </>
                                                                }
                                                                // loading={loading}
                                                                formik={formik}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </FormikForm>
                                        // Property Search Form End
                                    )}
                                </Formik>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    )
}

export default PropertySearch
